



















































































































































import Vue from "vue";
import { saveOrUpdateCourse, getCourseById } from "@/api/course";
import CourseImage from "./CourseImage.vue";
import TextEditor from "@/components/TextEditor/index.vue";
import moment from "moment";

export default Vue.extend({
  name: "CreateOrUpdateCourse",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    courseId: {
      type: [String, Number]
    }
  },
  components: {
    CourseImage,
    TextEditor
  },
  data() {
    return {
      activeStep: 0,
      steps: [
        { title: "基本信息", icon: "el-icon-edit" },
        { title: "课程封面", icon: "el-icon-edit" },
        { title: "销售信息", icon: "el-icon-edit" },
        { title: "秒杀活动", icon: "el-icon-edit" },
        { title: "课程详情", icon: "el-icon-edit" }
      ],
      course: {
        // id: 0,
        courseName: "",
        brief: "",
        teacherDTO: {
          // id: 0,
          // courseId: 0,
          teacherName: "",
          teacherHeadPicUrl: "",
          position: "",
          description: ""
        },
        courseDescriptionMarkDown: "",
        price: 0,
        discounts: 0,
        priceTag: "",
        discountsTag: "",
        isNew: true,
        isNewDes: "",
        courseListImg: "",
        courseImgUrl: "",
        sortNum: 0,
        previewFirstField: "",
        previewSecondField: "",
        status: 0, // 0：未发布，1：已发布
        sales: 0,
        activityCourse: false, // 是否开启活动秒杀
        activityCourseDTO: {
          // id: 0,
          // courseId: 0,
          beginTime: "",
          endTime: "",
          amount: 0,
          stock: 0
        },
        autoOnlineTime: ""
      }
    };
  },
  created() {
    if (this.isEdit) {
      this.loadCourse();
    }
  },
  methods: {
    async loadCourse() {
      const { data } = await getCourseById(this.courseId);
      const { activityCourseDTO } = data.data;
      activityCourseDTO.beginTime = moment(activityCourseDTO.beginTime).format("YYYY-MM-DD");
      activityCourseDTO.endTime = moment(activityCourseDTO.endTime).format("YYYY-MM-DD");
      this.course = data.data;
    },

    async handleSave() {
      const { data } = await saveOrUpdateCourse(this.course);
      if (data.code === "000000") {
        this.$message.success("保存成功");
        this.$router.push("/course");
      } else {
        this.$message.error("保存失败");
      }
    }
  }
});
