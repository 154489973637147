import { render, staticRenderFns } from "./CreateOrUpdate.vue?vue&type=template&id=3c0469af&scoped=true&"
import script from "./CreateOrUpdate.vue?vue&type=script&lang=ts&"
export * from "./CreateOrUpdate.vue?vue&type=script&lang=ts&"
import style0 from "./CreateOrUpdate.vue?vue&type=style&index=0&id=3c0469af&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0469af",
  null
  
)

export default component.exports