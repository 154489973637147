




import Vue from "vue";
import E from "wangeditor";

export default Vue.extend({
  name: "TextEditor",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  // 组件已经渲染好，可以初始化操作 DOM 了
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor() {
      const editor = new E(this.$refs.editor as any);
      // 注意：事件监听必须在 create 之前
      editor.config.onchange = (value: string) => {
        this.$emit("input", value);
      };
      editor.create();

      // 注意：设置初始化必须在 create 之后
      editor.txt.html(this.value);
    }
  }
});
